import { Link, useNavigate } from "react-router-dom";
import {
  LogoutIcon,
  RequestIcon,
  SettingIcon,
  UserIcon,
  CartIcon,
  SubscriptionIcon,
  THistoryIcon,
} from "../../../icons/icons";
import "./sidenav.css";
import { ROUTES } from "../../../routes/route";
import { useSelector } from "react-redux";
import { Badge } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import HomeIcon from '@mui/icons-material/Home';
import MoneyIcon from '@mui/icons-material/Money';


function SideNav({ userDetail, toggleSideNav, confirmLogout }) {
  const navigate = useNavigate();

  const { width } = useWindowSize({});

  const { allProducts } = useSelector((state) => state?.product);

  const color = "#033F4B";
  const menu_data = [
    { path: ROUTES.shop, name: "Home", icon: <HomeIcon color={color} /> },
    {
      path: ROUTES.request,
      name: "Request",
      icon: <RequestIcon color={color} />,
    },
    { path: ROUTES.credit, name: "Credit", icon: <MoneyIcon color={color} /> },
    { path: ROUTES.shop, name: "Setting", icon: <SettingIcon color={color} /> },
    {
      path: ROUTES.cart,
      name: "Cart",
      icon: allProducts?.products?.length ? (
        <Badge
          size="small"
          color="orange"
          count={allProducts?.products?.length}
        >
          <CartIcon color={color} />
        </Badge>
      ) : (
        <CartIcon color={color} />
      ),
    },
    {
      path: ROUTES.subscription,
      name: "Subscription plan",
      icon: <SubscriptionIcon color={color} />,
    },
    {
      path: ROUTES.user_transaction_history,
      name: "Transaction History",
      icon: <THistoryIcon color={color} />,
    },
  ];

  return (
    <>
      <main className={`side_nav_container tw-z-20`}>
        <section className="">
          {width <= 991 && (
            <button
              className="btn rounded profile_name"
              onClick={() => {
                navigate(ROUTES.profile);
                window.innerWidth < 768 && toggleSideNav();
              }}
            >
              {userDetail
                ? `${userDetail?.first_name} ${userDetail?.last_name}`
                : "Anonymous user"}
            </button>
          )}
          <section className="menu_list my-3">
            <div className="tw-flex tw-flex-col tw-gap-y-2">
              {menu_data.map((menu, index) => {
                return (
                  <div className="" key={index}>
                    <Link
                      to={menu.path}
                      className="side_nav_menu d-flex gap-3 tw-py-3"
                      onClick={() => {
                        window.innerWidth < 768 && toggleSideNav();
                      }}
                    >
                      {menu.icon}
                      <span>{menu.name}</span>
                    </Link>
                    <hr />
                  </div>
                );
              })}
             
            </div>
            <div
              className="side_nav_menu d-flex gap-2"
              style={{ cursor: "pointer", paddingBottom: "5vh" }}
              onClick={confirmLogout}
            >
              <LogoutIcon />
              <p className="my-auto">
                <span>Logout</span>
              </p>
            </div>
          </section>
        </section>
      </main>
    </>
  );
}

export default SideNav;
