import { Button } from "@mui/material";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { formatNumber, formNumberOnly } from "../../../utils/formatNumber";
import { useLocation } from "react-router-dom";
import { useUserData } from "../../hooks/useUserData";
import { usePaymentModal } from "../../../redux/hooks/zustands/usePaymentModal";
import { useInitiateRepayment } from "../../../redux/mutation/postMutation";
import { LoadingButton } from "@mui/lab";

function ShoppingHistory({ rows, handleOpenDialog, handleOpenDetailModal }) {
  const location = useLocation({}).pathname;

  const [isLoading, setIsLoading] = useState({state: false, id: null})


  const paymentMutation = useInitiateRepayment();


  const { openModal:openPaymentModal} = usePaymentModal();

  const { data } = useUserData();

  const sub_repaymentDuration = data?.subscriptions?.length
    ? data?.subscriptions[0]?.plan?.repayment_duration
    : null;

  const statusColor = {
    pending: "alert-warning",
    "order in progress": "alert-warning",
    repaid: "alert-success",
    "order delivered": "alert-success",
    rejected: "alert-danger",
    declined: "alert-danger",
    canceled: "alert-danger",
    "instant payment": "alert-success",
    incomplete: "alert-warning",
  };

  const formatElectricName = useCallback((val) => {
    let splittedVal = val.split("_");
    return splittedVal[0] + " " + splittedVal[1];
  });



    const openPayment=(order)=>{
      setIsLoading({state: true, id: order?.id})

      paymentMutation.mutate({
        order_id: order?.id,
        "payment_type":"transfer"
      }, {
        onSuccess: (res) => {
          setIsLoading(false)
          console.log(res?.data)
          openPaymentModal({...res?.data?.data, email: data?.email})
        },
        onError: (error) => {
          console.log(error)
          setIsLoading({state: false})
        }
      })
      
    }

  return (
    <>
      <section className="col-lg-11 mx-auto">
        {rows?.length ? (
          rows?.map((row, index) => {
            return (
              <div
                key={row?.id + "____order"}
                className="row mx-3 px-2 pb-3 pt-3 my-3 bg-white"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "10px",
                }}
              >
                <div className="col-lg-3 col-md-5 d-flex flex-column gap-2">
                  <p>
                    Order ID :{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {row?.order_number}
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {row?.is_bill ? "Bills Payment" : row?.market_place?.name}
                    </span>
                  </p>
                  {/* <p>
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {" "}
                      {row?.status === "pending"
                        ? sub_repaymentDuration + "days"
                        : moment(row?.repaymentDuration).format(
                            "DD MMMM, YYYY"
                          )}
                    </span>
                  </p> */}
                </div>
                <div className="col-lg-9 col-md-7 d-flex flex-column gap-2">
                  <div className="d-flex  justify-content-between flex-wrap">
                    <p>
                      Date :{" "}
                      <span>
                        {moment(row?.createdAt).format("DD MMM, YYYY h:mm a")}
                      </span>
                    </p>
                    <p
                      className={`cursor-pointer mx-3 border-0 alert ${
                        statusColor[row?.status]
                      } rounded p-1 px-3`}
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <i>{row?.status}</i>
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 d-flex flex-column gap-2">
                      <div className="d-flex flex-column gap-3 border p-3">
                        {row?.products?.map((item, index) => (
                          <div key={index + "_____product"}>
                            <div
                              className="tw-flex tw-gap-x-16 tw-items-center justify-content-betwee flex-wra"
                              style={{
                                borderRadius: "6px",
                                borderColor: "#CFCDCC4D",
                              }}
                            >
                              <div className="tw-flex tw-gap-x-3">
                                <p>
                                  {row?.is_bill ? (
                                    <span className="d-flex flex-column">
                                      <small
                                        style={{
                                          fontWeight: 300,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {row?.order_name}
                                      </small>
                                      <span
                                        style={{
                                          fontWeight: 600,
                                          fontSize: "16px",
                                        }}
                                      >
                                        {item?.disco}
                                        {/* {row?.order_name === "electricity"
                                            ? formatElectricName(
                                                item?.disco
                                              )
                                            : (item?.disco)?.toUpperCase()} */}
                                      </span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "16px",
                                      }}
                                    >
                                      {item?.name}
                                    </span>
                                  )}
                                </p>
                                {row?.is_bill ? null : (
                                  <div>
                                    <a
                                      href={item?.url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="nowrap cursor-pointer rounded p-1 text-decoratio tw-font-[400]"
                                      style={{
                                        color: "#033F4B",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                      }}
                                    >
                                      View Item link
                                    </a>
                                  </div>
                                )}
                              </div>
                              <div>
                                <p>
                                  {/* Subtotal :{" "} */}
                                  <span>
                                    {formatNumber(
                                      (row?.is_bill
                                        ? item?.amount ?? item?.total_amount
                                        : item?.price) ?? 0
                                    )}
                                  </span>
                                </p>
                                {row?.is_bill ? (
                                  ""
                                ) : (
                                  <>
                                    {/* <div
                                      className="py-2 px-3 rounded-3 border"
                                      style={{ backgroundColor: "#fff" }}
                                    >
                                      QTY: {item?.quantity}
                                    </div> */}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-cente mt2-3">
                        <p>
                          Total Amount :{" "}
                          <span style={{ fontWeight: 600, fontSize: "16px" }}>
                            {formatNumber(row?.total_amount)}
                          </span>
                        </p>
                      </div>
                    </div>

                    {/* <div className="col-lg-5 d-flex flex-column align-items-end gap-2 justify-content-betwee "> */}
                    <div className="col-lg-5 d-flex my-3 justify-content-between align-items-en">
                      <div>
                        {/* <span
                          className={`cursor-pointer border-0 alert ${statusColor[row?.status]} rounded p-1 px-3`}
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <i>{row?.status}</i>
                        </span> */}
                      </div>
                      <div className="tw-flex tw-flex-col tw-gap-y-8">
                        <Button
                          sx={{
                            backgroundColor: "#033F4B",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#033F4B",
                            },
                          }}
                          className="tw-rounded-md tw-capitalize"
                          onClick={() => handleOpenDialog("pay", row)}
                        >
                          View Detail
                        </Button>

                        {(row?.status === "order delivered" || row?.status==='repaid') && (
                          <LoadingButton
                          loading={isLoading.state && isLoading.id===row?.id}
                          loadingPosition="center"
                          className="tw-capitalize"
                            sx={{
                              backgroundColor: "#033F4B",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#033F4B",
                              },
                              "&:disabled": {
                                backgroundColor: "gray",
                                cursor: "not-allowed"
                              
                              }
                            }}
                            
                            disabled={(row?.status==='repaid' || row?.status==='instant payment') ? true : false}
                            onClick={() => openPayment(row)}
                          >
                            {
                              (row?.status==='repaid' || row?.status==='instant payment') ? "Paid" : "Pay"
                            }
                          
                          </LoadingButton>
                        )}

                        {/* {location?.includes("credit") &&
                        row?.status !== "repaid" ? (
                          <Button
                            sx={{
                              backgroundColor: "#033F4B",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#033F4B",
                              },
                            }}
                            onClick={() => handleOpenDialog("pay", row)}
                          >
                            Pay
                          </Button>
                        ) : (
                          <span
                            className="nowrap py-1 px-3"
                            style={{
                              backgroundColor: "#03010033",
                              color: "#000",
                              cursor: "pointer",
                              borderRadius: "6px",
                              fontWeight: 500,
                            }}
                            onClick={() => handleOpenDialog("pay", row)}
                          >
                            {row?.status === "order delivered"
                              ? "Pay"
                              : "View Detail"}
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className={`card-tex text-center my-2 text-muted fs-5`}>
            <i>No data recorded here!!</i>
          </p>
        )}
      </section>

      {/* <section className="col-lg-10 mx-auto card">
       
        <div className="card-body">
          {rows?.length > 0 ? (
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {tableHead?.map((item, index) => {
                      return (
                        <TableCell key={index} align={item?.align}>
                          <b>{item.name}</b>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, index) => {
                    return (
                      <TableRow key={index} hover>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          {row?.market_place?.name}
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <div style={{ width: "20vh" }}>
                            <ul className="list-unstyled">
                              {row?.products?.map((item, index) => (
                                <li key={index + "___item"} className="pb-1">
                                  {item?.name}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </TableCell>
                        <TableCell align="right" sx={{ verticalAlign: "top" }}>
                          <ul className="list-unstyled">
                            {row?.products?.map((item, index) => (
                              <li
                                key={index + "___amount"}
                                className="pb-1 d-flex align-items-center justify-between fw-semibold gap-"
                              >
                                <div
                                  className="py-2 px-3 rounded-3 border"
                                  style={{ backgroundColor: "#fff" }}
                                >
                                  QTY: {item?.quantity}
                                </div>
                                <span className="ms-auto">
                                  {formNumberOnly(item?.price)}
                                </span>
                              </li>
                            ))}
                            <li
                              className="pb-1 pt-3 d-flex align-items-center fw-semibold gap-2 "
                              style={{ verticalAlign: "bottom" }}
                            >
                              <span
                                className="nowrap rounded p-1"
                                style={{
                                  backgroundColor: "#033F4B",
                                  color: "#fff",
                                  cursor: "default",
                                }}
                              >
                                Total Amount
                              </span>
                              {formatNumber(row?.total_amount)}
                            </li>
                          </ul>
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }}>
                          <ul className="list-unstyled">
                            {row?.products?.map((item, index) => (
                              <li key={index + "__link"} className="pb-1">
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-decoration-none"
                                >
                                  View
                                </a>
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell
                          sx={{ verticalAlign: "top" }}
                          className="nowrap"
                        >
                          {row?.id}
                        </TableCell>
                        <TableCell
                          sx={{ verticalAlign: "top" }}
                          className="nowrap"
                        >
                          {moment(row?.createdAt).format("LL")}
                        </TableCell>
                        <TableCell
                          sx={{ verticalAlign: "top" }}
                          className=" nowra"
                        >
                          <p style={{ width: "45vh" }}>
                            {row?.deliveryLocation}
                          </p>
                        </TableCell>
                        <TableCell sx={{ verticalAlign: "top" }} align="center">
                          {row?.repaymentStatus ? "Paid" : "Not Paid"}
                        </TableCell>
                        <TableCell>
                          <div className="d-flex gap-2">
                            <div
                              className="rounded-circle my-auto"
                              style={{
                                backgroundColor:
                                  row?.status === "pending"
                                    ? "#F8D86E"
                                    : row?.status === "approved"
                                    ? "#A76212"
                                    : row?.deliveryStatus
                                    ? "green"
                                    : "#F8D86E",
                                height: "10px",
                                width: "10px",
                              }}
                            ></div>
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                width: "4vh",
                                height: "4vh",
                                backgroundColor: "#333332",
                                borderRadius: "50%",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => handleOpenDialog("pay", row)}
                            >
                              <MoreIcon color={"white"} />
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              <p className={`card-tex text-center my-2 text-muted fs-5`}>
                <i>You have not made any purchase yet.</i>
              </p>
            </>
          )}
        </div>
      </section> */}
    </>
  );
}

export default ShoppingHistory;
