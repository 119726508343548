import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import {TextField, Select, MenuItem} from "@mui/material";
import { states } from "../../redux/states";
import { useUpdateAddress } from "../../redux/mutation/postMutation";
import { useGetPosition } from "../hooks/useGetPosition";
export default function UpdateAddress({ handleClick, handleClose, data }) {
    const [isLoading, setIsLoading] = useState(null);
    const updateMutation = useUpdateAddress();
    const position = useGetPosition({});
  const formik = useFormik({
    initialValues: {
      city: data?.city??"",
      state: data?.state??"",
      street: data?.street?? data?.address ?? "",
      longitude: position?.longitude??0,
      latitude: position?.latitude??0
    },
    enableReinitialize: true,
    onSubmit:(values)=>{
        
        updateMutation.mutate(values);
    }
  });

  useEffect(()=>{
    if(updateMutation.isLoading){
      setIsLoading(true);
    }
    else if(updateMutation.isError){
      setIsLoading(false);
      const msg = updateMutation?.error?.response?.data?.message
      handleClick(msg, "error");
    }
    else if(updateMutation.isSuccess){
      setIsLoading(false)
      const res = updateMutation?.data?.data
      if(res?.success) { handleClick(res?.message, "success"); handleClose() }
      else{
           handleClick(res?.message, "error");
       }
    }
  }, [updateMutation?.status])

  return (
    <>
    {
      <main>
        <h4 className="text-center">Address Information</h4>
        <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
            <div className="city">
                <label htmlFor="city">City</label>
                <TextField
                type="text"
                id="standard-basic"
                name="city"
                onChange={formik?.handleChange}
                value={formik?.values.city}
                onBlur={formik?.handleBlur}
                placeholder="e.g. victoria island"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
               <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.city && formik.errors.city}
            </small>
            </div>
          <div className="state">
                <label htmlFor="state">Employer's Address</label>
                <Select
                name="employeraddress"
                value={formik?.values.state}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                fullWidth
                variant="outlined"
                sx={{ borderRadius: "30px" }}
                size="small"
              >
                {
                  states.map((state, index)=>{
                    return(
                      <MenuItem key={index} value={state}>{state}</MenuItem>
                    )
                  })
                }
              </Select>
            </div>
            <div className="street">
                <label htmlFor="street">Street</label>
                <TextField
                type="text"
                id="standard-basic"
                name="street"
                onChange={formik?.handleChange}
                value={formik?.values.street}
                onBlur={formik?.handleBlur}
                placeholder="e.g. Igwe mgbe str"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
               <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.street && formik.errors.street}
            </small>
            </div>

          <CustomButton text={"Save"} loading={isLoading}/>
        </form>
      </main>

      }
    </>
  );
}
