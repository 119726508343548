import React, { useState, useEffect } from 'react'
import Form from './Form'
import ConfirmAirtimeInformation from './ConfirmAirtimeInformation';
import AirtimeCheckout from './AirtimeCheckout';
import BillWarningPage from '../BillWarningPage';

export default function Airtime({formik, handleClick, savedData, openModal}) {


    const [nextAction, setNextAction ] = useState("");

    useEffect(()=>{
        if(savedData){
            setNextAction('checkout')
        }
    }, [savedData])

    // const billData = {
    //     phone: formik?.values?.mobileNumber,
    //     meter: formik?.values?.mobileNumber,
    //     // customer_phone: formik?.values?.mobileNumber,
    //     amount: formik?.values?.amount,
    //     service_type: formik?.values?.service?.service_type,
    //     disco: formik?.values?.service?.service_type,
    //     plan: formik?.values?.service?.plans?.length ? formik?.values?.service?.plans[0] : "",
    //     vendType: formik?.values?.service?.plans?.length ? formik?.values?.service?.plans[0] : "",
    //     email: formik?.values?.email,
    //     marketId: formik?.values?.marketPlace?.id,
    //     name: "Dianna Doyle",
    //     order_name: "airtime",
    //     vertical: "VTU"
    //   };

    const billData = {
        phone: formik?.values?.mobileNumber,
        meter: formik?.values?.mobileNumber,
        amount: formik?.values?.amount,
        disco: formik?.values?.service,
        vendType: "PREPAID",
        email: formik?.values?.email,
        marketId: formik?.values?.marketPlace?.id,
        name: formik?.values?.name,
        vertical: "VTU",
        order_name: "airtime",
        payment_type: "transfer"
      };


      const payloadData = savedData ? savedData : {
        data: {
            marketId: formik?.values?.marketPlace?.id,
            deliveryLocation: "",
            order_type: "bills",
            order_name: "airtime",
            products: [
                billData
            ],
        },
      };


  return (
    <>
        <main>
            {
                 nextAction==='maximum_exceeded'? (<BillWarningPage handleClick={handleClick} payloadData={billData}/>):
                nextAction==='confirm_information'? (
                    <ConfirmAirtimeInformation formik={formik} handleClick={handleClick} setNextAction={setNextAction} payloadData={payloadData}/>
                ): nextAction==='checkout'? (
                    <AirtimeCheckout formik={formik} handleClick={handleClick} setNextAction={setNextAction} payloadData={payloadData} openModal={openModal}/>
                ) : (
                    <Form formik={formik} setNextAction={setNextAction}/>
                )
            }

        </main>
    </>
  )
}
