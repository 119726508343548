import {useState} from "react";
import { formNumberOnly, formatNumber } from "../../../utils/formatNumber";
import moment from "moment";
import { Button } from "antd";

export default function CableReceipt({ data, isLoading, cancelOrder, continueTransaction }) {

const [name, setName] = useState("");


  const handleClick=(val)=>{
    setName(val)
      if(val==='continue'){
        continueTransaction(data?.order?.id)
      }
      else {
        cancelOrder()
      }
  }


  return (
    <>
      <div className="col-lg-8 col-12 mx-lg-auto mx-2">
        <h3
        className="text-center"
          style={{
            fontWeight: 500,
            fontSize: "25px",
            color: "#323C4D",
          }}
        >
          Transaction Receipt
        </h3>

        <div className="col-lg-10 mx-auto">
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Email
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className="text-capitalize"
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
               {data?.order?.products[0]?.email ?? "******"}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Service Type
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className="text-capitalize"
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                  Cable
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Plan
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {data?.order?.products?.[0]?.plan ?? "******"}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Amount
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
               
               NGN {formNumberOnly(data?.order?.sub_total ?? 0)}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Surcharge
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                NGN {formNumberOnly(100)}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Total Amount
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
           NGN {formNumberOnly(parseFloat(data?.order?.total_amount ?? 0)+100)}
              </small>
            </div>
          </div>
        </div>
       {data?.order?.status === "instant payment" ? null : (
          <div className="text-center d-flex align-items-center my-3">
            <label
              htmlFor="terms_condition"
              className="mx-2"
              style={{ fontSize: "17px", fontWeight: 500 }}
            >
              This is bought on credit and to be paid on{" "}
              {moment(data?.order?.repaymentDuration).format("DD MMMM, YYYY")}
            </label>
          </div>
        )}

          {(data?.order?.status === "incomplete" || data?.order?.status === "pending") && (
          <div className="tw-flex tw-flex-wrap tw-gap-8 tw-justify-center mt-3">
        
          <Button
            shape="round"
            size="large"
            style={{
              background: "#033F4B",
              color: "#fff",
              fontSize: "18px",
            }}
            loading={name==="continue" && isLoading}
            
            onClick={()=>handleClick("continue")}
          >
            Continue transaction
          </Button>

          <Button
            shape="round"
            size="large"
            style={{
              color: "#fff",
              fontSize: "18px",
              backgroundColor: "red"
            }}
            loading={name==="cancel" && isLoading}
            
            onClick={()=>handleClick("cancel")}
          >
            Cancel transaction
          </Button>


          </div>
        )}
      </div>
    </>
  );
}
