/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import plan1 from "../../assets/regular.png";
import plan2 from "../../assets/silver.png";
import plan3 from "../../assets/gold.png";
import {
  useSetLimit,
  useApproveKyc,
} from "../../redux/mutation/AdminPostMutation";
import { Box, Button, CircularProgress } from "@mui/material";
import CustomButton from "../CustomButton/CustomButton";
import {
  useGetSubscriptionPlan,
  useGetUserSubscriptionPlan,
  useGetUserOrder,
  useGetUserUpperPlans,
} from "../../redux/hooks/getHooks";
import {
  useSubscribePlan,
  useDeleteSubscription,
} from "../../redux/mutation/postMutation";
import PlanDuration from "./PlanDuration";
import ActionButton from "./ActionButton";
import { IsLoadingLoader, IsErrorLoader } from "../../utils/Loader";
import { formNumberOnly, formatNumber } from "../../utils/formatNumber";
import { useUserData } from "../hooks/useUserData";
import { ROUTES } from "../../routes/route";
import { Modal } from "antd";
import "./subscribePlan.css";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { stepperAction } from "../../redux/slice/stepperSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import { usePaymentModal } from "../../redux/hooks/zustands/usePaymentModal";

function SubscriptionPlan({
  role,
  declineUserFn,
  eachUser,
  handleClick,
  handleClose,
}) {
  const approveKycMutation = useApproveKyc(eachUser?.id);
  const subscribeMutation = useSubscribePlan({});

  const [selectedPlan, setSelectedPlan] = useState(0);
  const [isLoading, setIsLoading] = useState(null);

  const [isOpen, setIsOpen] = useState(false)

  const [newPlanLoading, setNewPlanLoading] = useState(null);

  const [selectedValue, setSelectedValue] = useState({
    name: "monthly",
    month: 1,
  });

  const [cardHover, setCardHover] = useState({ status: false, index: null });

  const [confirmModal, setConfirmModal] = useState(false);

  const { initialState } = useSelector((state) => state.stepper);

  const [subscriptionDetail, setSubscriptionDetail] = useState({});

  const [modal, contextHolder] = Modal.useModal({});


  const { openModal:openPaymentModal} = usePaymentModal();

  const [planToSubscribe, setPlanToSubscribe] = useState({
    plan: null,
    index: null,
  });

  const dispatch = useDispatch();

  const location = useLocation({})?.pathname;

  // const deleteSubscription = useDeleteSubscription(subscriptionDetail?.transaction_id);
  const deleteSubscription = useDeleteSubscription(
    subscriptionDetail?.transaction_id
  );

  const [subscribeLoading, setSubscribeLoading] = useState({
    status: false,
    index: null,
  });

  const getPendingOrders = useGetUserOrder("all");
  const getUpperPlans = useGetUserUpperPlans();

  const orders = getPendingOrders?.data?.data?.data;

  const upperPlans = getUpperPlans?.data?.data?.data;

  // Accrued dept=========================
  const currentDept = orders?.reduce(
    (acc, item) => acc + parseFloat(item?.total_amount),
    0
  );
  //=============

  const { data: user } = useUserData();

  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : null;
    const expirationDate = new Date(subscription?.dueDate);
    const currentDate = new Date();
  const freePlanExpired = subscription?.plan?.is_free && currentDate > expirationDate

  const checkDueDate = (date) => {
    return new Date(date) < new Date();
  };

  //repayment date
  const repaymentDate =
    user?.subscriptions?.length &&
    moment(subscription?.createdAt)
      .add(user?.plan?.repayment_duration, "days")
      .format("DD MMMM, YYYY");

  // const repayment_default = new Date(repaymentDate) < new Date();
  const repayment_default = checkDueDate(repaymentDate);

  const getSubscriptionPlan = useGetSubscriptionPlan();
  const getUserSubscriptionPlan = useGetUserSubscriptionPlan();
  let plansData =
    role === "admin" || role === "user_plans"
      ? getSubscriptionPlan?.data?.data?.data
      : getUserSubscriptionPlan?.data?.data?.data;

  const planDataIsLoading =
    role === "admin" || role === "user_plans"
      ? getSubscriptionPlan?.isLoading
      : getUserSubscriptionPlan?.isLoading;

  const planDataIsError =
    role === "admin" || role === "user_plans"
      ? getSubscriptionPlan?.isError
      : getUserSubscriptionPlan?.isError;

  // console.log(getUserSubscriptionPlan?.data?.data?.data);

  const navigate = useNavigate();
  const changePlan = (index, item) => {
    // console.log(item)
    setSelectedPlan(item.id);
  };
  // console.log(user)

  const approveKyc = (val) => {
    // "This will approve the subscription plan"
    // console.log(eachUser)
    const json = {
      "allowed_plan": selectedPlan,
      "approved_kyc": val==='kyc'? true: false
    }

    approveKycMutation.mutate(json);

    console.log(json)
    // if (eachUser.kyc_status) {
    //   approveKycMutation.mutate({
    //     userId: eachUser?.id,
    //     allowed_plan: selectedPlan,
    //   });
    // } else {
    //   handleClick("This user kyc is yet to be complete", "error", {
    //     vertical: "top",
    //     horizontal: "right",
    //   });
    // }
  };

  const cancelPlanFn = () => {};

  //subcription button function
  const subscribeFn = (plan, index) => {
    setPlanToSubscribe({ plan, index });
    if (!selectedValue.name) {
      handleClick("Kindly select the duration of your plan", "error", {
        vertical: "top",
        horizontal: "right",
      });
    } else if (user?.kyc_status) {
      // am using the index to target the subscription clicked to know the one to add the circular progress when it's loading
      if (subscription?.status) {
        modal.confirm({
          title: "Confirm",
          icon: <ExclamationCircleOutlined />,
          content:
            "Note: that you are changing to a new subscription plan, your current plan will be cancelled and you will not be able to reverse this action",
          okText: "Continue",
          cancelText: "Cancel",
          onOk: () => {
            setSubscribeLoading({ ...subscribeLoading, status: true, index });
            executeSubscription(plan);
          },
        });
      } else {
        setSubscribeLoading({ ...subscribeLoading, status: true, index });
        executeSubscription(plan);
      }
    } else {
      modal.confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: "You need to complete your kyc before subscription",
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => {
          if (user?.data?.kyc_stepper === 0 && initialState === "start") {
            navigate(ROUTES.kyc);
          }
          // Add other conditions and state updates here
          else if (user?.data?.kyc_stepper < 5) {
            dispatch(stepperAction.setStep(user?.data?.kyc_stepper));
            navigate(ROUTES.complete_kyc);
          } else if (user?.data?.kyc_stepper === 5) {
            navigate(ROUTES.otp_verification);
          } else if (user?.data?.kyc_stepper === 6) {
            navigate(ROUTES.bank_statement);
          }
          // navigate(ROUTES.kyc);
        },
      });
    }
  };

  const executeSubscription = (plan) => {
    subscribeMutation.mutate({
      planId: plan?.id,
      subscription_type: selectedValue.name,
      // payment_type: "ussd",
      payment_type: "transfer"
    });
  };

  useEffect(() => {
    if (approveKycMutation.isLoading) {
      setIsLoading(true);
    } else if (approveKycMutation.isError) {
      setIsLoading(false);
      const msg = approveKycMutation?.error?.response?.data;

      handleClick(msg, "error");
    } else if (approveKycMutation.isSuccess) {
      setIsLoading(false);
      const msg = approveKycMutation?.data?.data;

      handleApproveClose()

      handleClick(msg?.message, "success");
      if (msg?.message === "User approved") {
        // invalidate the profile query
        navigate(ROUTES.adminDashboard);
        handleClose();
      }
    }
    // console.log(approveKycMutation?.data, approveKycMutation?.error);
  }, [approveKycMutation.status]);

  useEffect(() => {
    if (subscribeMutation.isError) {
      setSubscribeLoading({ ...subscribeLoading, status: false, index: null });
      const resError = subscribeMutation?.error?.response;
      let msg = subscribeMutation?.error?.response?.data?.message;
      let msg2 = subscribeMutation?.error?.response?.statusText;

      // const resError = err?.response;
      // setConfirmModal(true);
      if (resError?.data?.data?.transaction_id) {
        setSubscriptionDetail(resError?.data?.data);
        setConfirmModal(true);
      }

      // if (!subscription?.status) {
      //   setSubscriptionDetail(resError?.data);

      //   setConfirmModal(true);
      // }
      // console.log(msg);

      if (resError?.status !== 403) {
        handleClick(msg ?? msg2, "error", {
          vertical: "top",
          horizontal: "right",
        });
      }

      // console.log(msg ? msg : msg2, "error");
    } else if (subscribeMutation.isSuccess) {
      setSubscribeLoading({ ...subscribeLoading, status: false, index: null });
      const res = subscribeMutation?.data?.data;
      // console.log(subscribeMutation?.data?.data);
      sessionStorage.setItem("payment_purpose", "subscription");

      // window.location.href = res?.data?.link;

      console.log(res?.data)

      openPaymentModal(res?.data)

      // handleClick(res?.message, "success", {
      //   vertical: "top",
      //   horizontal: "right",
      // });
    }
  }, [subscribeMutation.status]);

  useEffect(() => {
    if (eachUser) {
      setSelectedPlan(eachUser?.allowed_plan);
    }
  }, []);

  const continueWithExistingPlan = () => {
    sessionStorage.setItem("payment_purpose", "subscription");

    openPaymentModal({...subscriptionDetail})

    // window.location.href = subscriptionDetail?.link;
    setConfirmModal(false);
  };

  const chooseNewPlan = () => {
    setNewPlanLoading(true);

    deleteSubscription?.mutate();
  };

  useEffect(() => {
    if (deleteSubscription.isLoading) {
      setNewPlanLoading(true);
    } else if (deleteSubscription.isError) {
      setNewPlanLoading(false);
      const res = deleteSubscription?.error?.response?.data;

      handleClick(res?.message, "error");
    } else if (deleteSubscription?.isSuccess) {
      setNewPlanLoading(false);
      const res = deleteSubscription?.data?.data;

      setSubscribeLoading({
        ...subscribeLoading,
        status: true,
        index: planToSubscribe?.index,
      });

      executeSubscription(planToSubscribe?.plan);
      // handleClick(res?.message, "success");
      setConfirmModal(false);
    }
  }, [deleteSubscription?.status]);

  const upgradeNavigate = () => {
    navigate(ROUTES.upgradePlans);
  };


  const handleApproveOpen=()=>{
    setIsOpen(true)
  }
  const handleApproveClose=()=>{
    setIsOpen(false)
  }

  return (
    <>
      <main className="col-lg-11 mx-auto">
        <section className="mx-2 text-center">
          <div className="d-flex flex-column mb-2">
            <h4 className="header fw-bold fs-5">
              {role === "admin"
                ? ""
                : role === "upgradePlan"
                ? "Subscription Plan"
                : "Your Eligible Subscription Plans"}
            </h4>
            <div>
              {role === "admin" ? (
                ""
              ) : role === "user_plans" ? (
                <div className="">
                  <p className="text-muted fw-bold text-center">
                    Activate maximum subscription plan
                  </p>
                  <p className="my-auto">
                    {eachUser?.first_name} {eachUser?.last_name}
                  </p>
                  <hr />
                  <p
                    className={`alert w-100 py-1 ${
                      eachUser?.kyc_status ? "alert-success" : "alert-danger"
                    } mx-2`}
                  >
                    {eachUser?.kyc_status
                      ? "Good to go, this user has completed kyc"
                      : "Quick note: This user is yet to complete kyc"}
                  </p>
                </div>
              ) : role === "upgradePlan" ? (
                <div className="">
                  <p className="text-muted fw-bold text-center">
                    Get more access to bigger ticket
                  </p>
                  <p>Choose your plan</p>
                  <PlanDuration
                    setSelectedValue={setSelectedValue}
                    selectedValue={selectedValue}
                  />
                </div>
              ) : (
                <div>
                  Choose your Plan
                  <PlanDuration
                    setSelectedValue={setSelectedValue}
                    selectedValue={selectedValue}
                  />
                </div>
              )}
            </div>
          </div>
          {/* <br /> */}
          <section className="row mx-lg-auto mx-2">
            {planDataIsLoading ? (
              <IsLoadingLoader />
            ) : planDataIsError ? (
              <IsErrorLoader />
            ) : plansData?.length ? (
              plansData
              ?.sort((a, b) => {
                if (a.id === subscription?.planId) return -1; // Active plan comes first
                if (b.id === subscription?.planId) return 1;  // Active plan comes first
                return 0; // Maintain original order otherwise
              })
                ?.sort((a, b) => a.amount - b.amount)
                ?.map((item, index) => {
                  return (
                    <div
                      className={`col-lg-4 col-md-6 col-sm-12 cursor-pointer position-relative my-3 mx-aut ${freePlanExpired && (item?.id===subscription?.planId) && 'd-none'}`}
                      key={index}
                      onClick={() => changePlan(index, item)}
                      onMouseEnter={() => {
                        setCardHover({ status: true, index });
                      }}
                      onMouseLeave={() => {
                        setCardHover({ status: false, index });
                      }}
                    >
                      <div
                        className="card h-100 d-flex flex-column justify-content-between pb-2"
                        style={
                          selectedPlan === item.id
                            ? {
                                border: "1px solid #033F4B",
                                background: "#F5F5F5",
                                transition: "all 0.5s",
                              }
                            : {
                                background: "#F5F5F5",
                                border: "none",
                                transition: "all 0.5s",
                              }
                        }
                      >
                        <div>
                          {role === "admin" && (
                            <ActionButton
                              plan={item}
                              handleClick={handleClick}
                            />
                          )}
                          <div className="d-flex flex-column justify-content-between">
                            <div
                              className="plan_header"
                              style={{
                                backgroundImage: `url(${
                                  item?.image ?? (
                                    item?.name === "Regular"
                                      ? plan1
                                      : item?.name === "Silver"
                                      ? plan2
                                      : plan3
                                  )
                                })`,
                              }}
                            >
                              <div className="plan_name d-flex align-items-center justify-content-center fw-bold rounded-circle bg-white text-dark">
                                {item?.name}
                              </div>
                            </div>

                            <div className="d-flex flex-column">
                              <h5 className="fs-5 fw-bold my-3">
                                
                                {item?.is_free ? (
                                  "Free"
                                ) : (
                                  <>
                                    ₦
                                    {formNumberOnly(
                                      parseFloat(
                                        item.amount * selectedValue?.month
                                      )
                                    )}{" "}
                                    <small
                                      className="text-capitalize"
                                      style={{ fontSize: "13px" }}
                                    >
                                      /{" "}
                                      {selectedValue?.name === "monthly"
                                        ? "month"
                                        : selectedValue?.name === "quarterly"
                                        ? "quarter"
                                        : selectedValue?.name === "annually"
                                        ? "annual"
                                        : selectedValue?.name}
                                    </small>
                                  </>
                                )}
                              </h5>
                            </div>
                            {/* <br /> */}
                            <div className="text-start px-4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              />
                              {/* <ul
                                  className="d-flex flex-column gap-1"
                                  style={{ width: "100%" }}
                                >
                                  <li>
                                    Shop up to{" "}
                                    <span className="fw-bold">
                                      {item?.name === "Regular"
                                        ? 20
                                        : item?.name === "Silver"
                                        ? 50
                                        : item?.name === "Gold" && 100}
                                      k worth of items across multiple
                                      marketplaces.
                                    </span>
                                  </li>
                                  <li>Pay later in days</li>
                                  <li>No interest charges</li>
                                  <li>
                                    Shop online marketplaces and physical
                                    pharmacies
                                  </li>
                                </ul> */}
                            </div>
                          </div>
                        </div>
                        {(role === "user" || role === "upgradePlan") && (
                          <div style={{ transition: "all 0.5s" }}>
                            {/* className={(cardHover.status && (cardHover.index === index))? 'd-block':'d-none'} */}
                            <CustomButton
                              text={
                                subscription?.planId === item.id &&
                                subscription?.status
                                  ? "Active Plan"
                                  : "Subscribe"
                              }
                              loading={
                                subscribeLoading.index === index &&
                                subscribeLoading.status
                              }
                              disabled={
                                subscription?.planId === item.id &&
                                subscription?.status
                              }
                              functionFn={() => subscribeFn(item, index)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
            ) : (
              <p className={`card-text text-center my-2`}>
                No Subscription plan available!!
              </p>
            )}
            {
              (role !== "user_plans" || role !== "user_plans") && (
                upperPlans?.length ? (
  
                <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                  <div className="d-flex gap-2 justify-content-center h-100 align-items-center">
                    <Button
                      className="fw-bold cursor"
                      sx={{
                        backgroundColor: "#323333",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#323333",
                        },
                      }}
                      onClick={upgradeNavigate}
                    >
                      + Upgrade plan 
                    </Button>{" "}
                    <p>to access more credit</p>
                  </div>
                </div>
              ): null
              )
            }
          </section>

          {/* {role === "upgradePlan" ? (
            <Button
              className="fw-bold cursor"
              sx={{
                textTransform: "capitalize",
                color: "#033F4B",
                fontSize: "2.5vh",
              }}
              onClick={handleClose}
            >
              I don't want to upgrade
            </Button>
          ) : (
            ""
          )} */}
          {role === "user_plans" && (
            <div className="d-flex justify-content-between">
              <Box sx={{ m: 1, position: "relative" }}>
                <button
                  className="btn my-2 rounded-pill mx-4 px-4"
                  style={{ backgroundColor: "#124B0D", color: "white" }}
                  // onClick={approveKyc}
                  onClick={handleApproveOpen}
                  // disabled={!eachUser?.kyc_status || isLoading || !selectedPlan}
                >
                  {eachUser?.status ? "Approved" : "Approve"}
                </button>
               
              </Box>
              <Box sx={{ m: 1, position: "relative" }}>
                <button
                  className="btn my-2 rounded-pill mx-2 px-4"
                  style={{ backgroundColor: "#BD0C0C", color: "white" }}
                  onClick={declineUserFn}
                  // disabled={!eachUser?.kyc_status || eachUser?.is_declined || eachUser?.status}
                >
                  {eachUser?.is_declined ? "Declined" : "Decline"}
                </button>
                {/* {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '30%',
              left: '45%',
              color: "#fff"
            }}
          />
        )} */}
              </Box>
            </div>
          )}
        </section>
      </main>

      <Modal
        title="Important Notice!!!"
        centered
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        footer={[
          <Button
            key="back"
            onClick={chooseNewPlan}
            sx={{
              border: "1px solid #033F4B",
              color: "#033F4B",
              marginRight: "1vh",
              marginTop: "1vh",
              textTransform: "capitalize",
              cursor: newPlanLoading ? "not-allowed" : "pointer",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "#fff",
              },
            }}
            disabled={newPlanLoading}
          >
            Create New Plan
            {newPlanLoading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "30%",
                  left: "45%",
                  color: "#fff",
                }}
              />
            )}
          </Button>,
          <Button
            key="continue_with_existing"
            type="primary"
            onClick={continueWithExistingPlan}
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              marginLeft: "1vh",
              marginTop: "1vh",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
              },
            }}
          >
            Continue with existing plan
          </Button>,
        ]}
      >
        <p className="my-3">
          You have a pending subscription, would you like to{" "}
          <b>CREATE NEW PLAN</b> or <b>CANCEL EXISTING PLAN</b>?
        </p>
      </Modal>



      <ModalDialogBox 
      open={isOpen}
      handleClose={handleApproveClose}
      modalSize={false}
      >
        <div className="px-4">
          <p>What do you want to approve</p>

          <Box sx={{ m: 1, position: "relative" }}>
                <button
                  className="btn my-2 w-100 rounded-pill px-4"
                  style={{ backgroundColor: "#124B0D", color: "white" }}
                  onClick={()=>approveKyc("kyc")}
                  // disabled={!eachUser?.kyc_status || isLoading || !selectedPlan}
                >
                  {eachUser?.approved_kyc ? "KYC Approved" : "Approve KYC"}
                </button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "30%",
                      left: "45%",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>

          <Box sx={{ m: 1, position: "relative" }}>
                <button
                  className="btn my-2 rounded-pill w-100 px-4"
                  style={{ backgroundColor: "#124B0D", color: "white" }}
                  onClick={()=>approveKyc("account")}
                  // disabled={!eachUser?.kyc_status || isLoading || !selectedPlan}
                >
                  {eachUser?.status ? "Account Approved" : "Approve Account"}
                </button>
                {isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "30%",
                      left: "45%",
                      color: "#fff",
                    }}
                  />
                )}
              </Box>

        </div>

      </ModalDialogBox>

      {contextHolder}
    </>
  );
}

export default SubscriptionPlan;
