import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./homeNav.css";
import Logo from "../../../components/logo/logo";
import { ROUTES } from "../../../routes/route";
import { CartIcon, MenuIcon, NotificationIcon } from "../../../icons/icons";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import useWindowSize from "../../../components/hooks/useWindowSize";
import { useSelector } from "react-redux";
import { Modal, Badge } from "antd";


function HomeNav({ toggleSideNav, openSideNav, userDetail, confirmLogout }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation({});
  const navigate = useNavigate({});

  const { allProducts } = useSelector(
    (state) => state.product
  );


  const windowSize = useWindowSize({});
  const currentPathName = location.pathname;
  const homeData = [
    {
      name: "Shop Now",
      path: ROUTES.shop,
      active: currentPathName === ROUTES.shop ? true : false,
    },
    {
      name: "My Request",
      path: ROUTES.request,
      active: currentPathName === ROUTES.request ? true : false,
    },
    {
      name: "My Credit",
      path: ROUTES.credit,
      active: currentPathName === ROUTES.credit ? true : false,
    },
    {
      name: "Subscription",
      path: ROUTES.subscription,
      active: currentPathName === ROUTES.subscription ? true : false,
    },
  ];

  const navLinkStyle = {
    padding: "1vh 2vh",
    border: "1.5px solid #033F4B",
    transition: "all 0.5s",
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //logout
  const navigateFn = (param) => {
    
      navigate(param);
      setAnchorEl(null);
    
  };


  return (
    <>
      <nav
        className={`navbar navbar-expand-lg sticky-top landing_page_nav tw-z-[99]`}
      >
        <div className="container-fluid px-lg-5 py-lg-2 py-2 px-md-4 px-2">
          <Link to={ROUTES.shop} className="navbar-brand p-0">
            <Logo />
          </Link>
          {
            windowSize.width > 768 && windowSize.width <= 991 &&
         ( <div className="nav-link" data-bs-toggle="collapse">
            <span onClick={() => toggleSideNav(!openSideNav)}>
              <MenuIcon clicked={openSideNav} />
            </span>
            </div>)
          }
          <div className="nav-link side_menu_bar" data-bs-toggle="collapse">
            <span onClick={() => toggleSideNav(!openSideNav)}>
              <MenuIcon clicked={openSideNav} />
            </span>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/* <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {homeData?.map((item, index) => {
                return (
                  <li className="nav-item" key={index}>
                    <Link
                      to={item.path}
                      className={`btn mx-2 px-2 my-auto rounded ${
                        item.active && "active_shop_btn"
                      }`}
                      style={navLinkStyle}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul> */}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item my-auto">
                <Link to={ROUTES.cart} className="nav-link">
                  {
                    allProducts?.products?.length? (
                      <Badge size="small" color="orange" count={allProducts?.products?.length}>
                        <CartIcon />
                      </Badge>
                    ): 
                    <CartIcon />
                  }
                  {/* <sup style={{
                    backgroundColor: "#033F4B",
                    height: '10px',
                    width: "10px",
                    color: "#fff",
                    borderRadius: "50%"
                  }}>{allProducts?.products?.length ? allProducts?.products?.length : ''}</sup> */}
                </Link>
              </li>
              <li className="nav-item my-auto">
                <Link to={ROUTES.shop} className="nav-link">
                  <NotificationIcon />
                </Link>
              </li>
              <li className="nav-item my-auto tw-h-10 tw-flex tw-items-center">
                <div className="nav-link">
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={userDetail?.profile_picture}
                      sx={{ width: 25, height: 25 }}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => navigateFn(ROUTES.profile)}>
                      Profile
                    </MenuItem>
                    <MenuItem onClick={() => navigateFn(ROUTES.user_transaction_history)}>
                      Transaction History
                    </MenuItem>
                    <MenuItem onClick={confirmLogout}>
                      Log out
                    </MenuItem>
                  </Menu>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  );
}

export default HomeNav;
