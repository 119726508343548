import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomButton from "../CustomButton/CustomButton";
import {TextField} from "@mui/material";
import { useUpdateEmployment } from "../../redux/mutation/postMutation";
export default function UpdateEmployment({ handleClick, handleClose, data }) {
    const [isLoading, setIsLoading] = useState(null);
    const updateMutation = useUpdateEmployment();
    // console.log(data);
  const formik = useFormik({
    initialValues: {
      occupation: data?.occupation??"",
      workEmail: data?.workEmail??"",
      salary: data?.salary??"",
      employer: data?.employer??"",
      employeraddress: data?.employeraddress??"",
    },
    enableReinitialize: true,
    onSubmit:(values)=>{
       
        updateMutation.mutate(values);
    }
  });

  useEffect(()=>{
    if(updateMutation.isLoading){
      setIsLoading(true);
    }
    else if(updateMutation.isError){
      setIsLoading(false);
      const msg = updateMutation?.error?.response?.data?.message
      handleClick(msg, "error");
    }
    else if(updateMutation.isSuccess){
      setIsLoading(false)
      const res = updateMutation?.data?.data
      if(res?.success) { handleClick(res?.message, "success"); handleClose() }
       else{
            handleClick(res?.message, "error");
        }
    }
  }, [updateMutation?.status])

  return (
    <>
      <main>
        <h4 className="text-center">Work Information</h4>
        <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
          <div className="occupation">
            <label
              htmlFor="occupation"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Occupation
            </label>
            <TextField
              className="rounded-pill"
              placeholder="e.g. accountant"
              name="occupation"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.occupation}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.occupation && formik.errors.occupation}
            </small>
          </div>
          <div className="email">
            <label
              htmlFor="workEmail"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Work Email
            </label>
            <TextField
              className="rounded-pill"
              placeholder="e.g. Johndoe@work.com"
              name="workEmail"
              type="email"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.workEmail}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.workEmail && formik.errors.workEmail}
            </small>
          </div>
          <div className="salary">
            <label
              htmlFor="salary"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Salary
            </label>
            <TextField
              className="rounded-pill"
              placeholder="e.g. 300,000"
              name="salary"
              type="number"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.salary}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.salary && formik.errors.salary}
            </small>
          </div>
          <div className="employer">
            <label
              htmlFor="employer"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Employer
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Enter place of work"
              name="employer"
              type="text"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.employer}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.employer && formik.errors.employer}
            </small>
          </div>
          <div className="employerAddress my-2">
                <label htmlFor="employerAddress">Employer Address</label>
                <TextField
                type="text"
                id="standard-basic"
                name="employeraddress"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values.employeraddress}
                placeholder="Enter employer's address"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
          <CustomButton text={"Save"} loading={isLoading}/>
        </form>
      </main>
    </>
  );
}
