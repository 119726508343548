
import React, { useState, useMemo } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { useTimeCounter } from "../hooks/useTimeCounter";
import { verifyPaymentFn } from "../../redux/fetchingFn";
import { useResponseModal } from "../../redux/hooks/zustands/useResponseModal";
import { Button } from "antd";
import { formNumberOnly } from "../../utils/formatNumber.js";
import MandateTransferResult from "../Shop/Mandate/MandateTransferResult";
import CheckingMandateTransfer from "../Shop/Mandate/CheckingMandateTransfer";
import { changePaymentMethod } from "../../redux/mutation/postMutation.js";
import { useUserData } from "../hooks/useUserData.js";

const PayWithTransfer = ({
  handleClick,
  setPaymentMethod,
  details,
  handleCloseModal,
  updateData

}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState({ status: false, index: null });

  const { openModal } = useResponseModal();

  const user = useUserData();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [transferStep, setTransferStep] = useState({
    step: 0,  
    state: "not sent",
    sentAt: null,
    message: "",
  
  });

  const { timeRemaining, formatTime, stopTimer } = useTimeCounter(
   details?.account_expires_at
  );

  //copying to clippoard
  const handleCopy = (val, index) => {
    navigator.clipboard
      .writeText(val)
      .then(() => {
        setCopied({ ...copied, status: true, index });
      })
      .catch((error) => {
        console.log(error);
        // handleClick("Error copying to clipboard: ", "error", {
        //   vertical: "top",
        //   horizontal: "right",
        // });
      });
  };

  const transferCred = useMemo(() => {
    return [
      { label: "Amount", value: details?.amount ?? 0, copiable: true },
      {
        label: "Account Number",
        value: details?.account_number ?? "",
        copiable: true,
      },
      { label: "Bank", value: details?.bank_name ?? "", copiable: false },
      {
        label: "Account Name",
        value: details?.account_name ?? "",
        copiable: false,
      },
    ];
  }, [details]);

  const confirmTransferDone = () => {
    // navigate(`/verify_payment?reference=${details?.reference}`)
    // handleCloseModal();

    setIsLoading(true);

    const currentTime = new Date(new Date().toISOString());

    // console.log(currentTime);

    setTransferStep({
      ...transferStep,
      step: 1,
      sentAt: currentTime,
      state: "sent",
    });

  //   const resp = {
  //    data: {
  //     "message": "Transaction fulfilled already",
  //     "data": {
  //         "order": {
  //             "products": [
  //                 {
  //                     "amount": 100,
  //                     "phone": "09160261836",
  //                     "meter": "09160261836",
  //                     "vendType": "PREPAID",
  //                     "disco": "MTN",
  //                     "vertical": "VTU",
  //                     "email": "jfixcoding@gmail.com",
  //                     "name": "Fixit",
  //                     "paymentType": "B2B",
  //                     "marketId": "6fc0383e-a73d-485b-ac29-29e02a841129",
  //                     "order_name": "airtime",
  //                     "payment_type": "transfer"
  //                 }
  //             ],
  //             "bill_response_data": {
  //                 "id": 79437744,
  //                 "tax": 0,
  //                 "name": "09160261836",
  //                 "disco": "MTN",
  //                 "token": null,
  //                 "units": 1,
  //                 "tariff": null,
  //                 "address": "09160261836",
  //                 "charges": "0",
  //                 "orderId": "862535705BCDED3D7FCCE965276D8268",
  //                 "parcels": [],
  //                 "phoneNo": null,
  //                 "vendRef": "2653921722346973",
  //                 "vendTime": "2024-07-30 14:42:54",
  //                 "receiptNo": "2024073014425393205154812",
  //                 "debtAmount": 0,
  //                 "vendAmount": 0,
  //                 "tariffClass": "STANDARD",
  //                 "tariffIndex": null,
  //                 "responseCode": 100,
  //                 "assetProvider": "MTN",
  //                 "debtRemaining": 0,
  //                 "demandCategory": "NMD",
  //                 "amountGenerated": "100.00",
  //                 "responseMessage": "SUCCESSFUL",
  //                 "totalAmountPaid": 100
  //             },
  //             "id": "035971a0-d1ca-4a35-a55a-bec92c07e7b9",
  //             "marketId": "6fc0383e-a73d-485b-ac29-29e02a841129",
  //             "userId": "cdf8ab29-d29e-423f-bb4e-e0ad3e439b33",
  //             "deliveryLocation": "",
  //             "order_number": "P-SGRI272509",
  //             "repaymentDuration": "2024-07-30T13:41:24.000Z",
  //             "status": "instant payment",
  //             "order_type": "items",
  //             "order_name": "airtime",
  //             "total_amount": "100",
  //             "sub_total": "100",
  //             "delivery_amount": "0",
  //             "current_service_charge": "0",
  //             "current_vat": "0",
  //             "vat": "0",
  //             "service_charge": "0",
  //             "sur_charge": "100",
  //             "deliveryStatus": true,
  //             "repaymentStatus": false,
  //             "email_sent": false,
  //             "instant_payment": true,
  //             "defaultDate": null,
  //             "default": false,
  //             "is_credit": false,
  //             "is_bill": true,
  //             "bill_status": true,
  //             "createdAt": "2024-07-30T13:41:24.000Z",
  //             "updatedAt": "2024-07-30T13:42:55.000Z",
  //             "user": {
  //                 "bioData": {},
  //                 "contact": {},
  //                 "employmentDetails": {},
  //                 "location": {},
  //                 "id": "cdf8ab29-d29e-423f-bb4e-e0ad3e439b33",
  //                 "first_name": "External",
  //                 "last_name": "Users",
  //                 "email": "externaluser@gmail.com",
  //                 "phone": "09164531611",
  //                 "password": "$2a$08$AUdZdCk1rzuSSdZDNp6eT.h2T5vk.wcNzl85FHcJFm0TCxe0OYBXa",
  //                 "email_verified": false,
  //                 "is_admin": false,
  //                 "profile_picture": null,
  //                 "mono_customer_id": null,
  //                 "mono_session_id": null,
  //                 "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNTkzMjUyNmQ0ZjQ3NDY2OTRkNmE2Yjc0NWE0NDQ5MzU1YTUzMzAzMDRkNmE0ZTZkNGM1NzRhNjk0ZTQ3NTU3NDVhNTQ0MjY4NWE0NDRlNmM0ZTQ0NGQzNTU5NmE0ZDdhIiwicm9sZSI6IjY0NTg0ZTZjNjM2NzNkM2QifSwiaWF0IjoxNzE2NjU2NTIyLCJleHAiOjE3MTY3NDI5MjJ9.WHXB2qDd7YDXuOmBC54DYWA4mHWcnlZlow9UAYLAitc",
  //                 "type": null,
  //                 "pin": null,
  //                 "pin_set": false,
  //                 "status": true,
  //                 "kyc_status": false,
  //                 "approved_kyc": false,
  //                 "phone_verified": false,
  //                 "otp": null,
  //                 "mono_id": null,
  //                 "token_expiry": null,
  //                 "password_reset_token": null,
  //                 "fcm_token": null,
  //                 "allowed_plan": "cae8b5d6-216d-44d1-8623-ec69aa2db733",
  //                 "selected_subType": null,
  //                 "role": "user",
  //                 "kyc_stepper": 0,
  //                 "deactivate": false,
  //                 "is_delete": false,
  //                 "is_declined": false,
  //                 "createdAt": "2024-05-25T17:02:02.000Z",
  //                 "updatedAt": "2024-05-25T17:10:15.000Z",
  //                 "account_number": null,
  //                 "bank_code": null,
  //                 "nip_code": null
  //             }
  //         }
  //     }
  //    }
  // }

    // details?.reference
    verifyPaymentFn({ queryKey: ["verify_transfer", details?.reference] })
      .then((res) => {
        // handleCloseModal();
        // openModal({ status: true, message: res?.data?.message });

        // console.log(res?.data?.data)

        setTransferStep({
          ...transferStep,
          step: 2,
          setAt: currentTime,
          state: "recieved",

        });

   

        setTimeout(() => {
          setTransferStep({
            ...transferStep,
            step: 3,
            setAt: currentTime,
            state: "success",
            message: res?.data?.message ?? "Order Complete",
            data: res?.data?.data
          });
        }, 3000);
      })
      .catch((error) => {
        // console.log(error);
        setIsLoading(true)
        
        setTimeout(() => {
          setIsLoading(false)
          setTransferStep({
            ...transferStep,
            step: 1,
            setAt: currentTime,
            state: "error",
            message: error?.response?.data?.message ?? error?.message,
          });
        }, 2000);

        // openModal({
        //   status: false,
        //   message: error?.response?.data?.message ?? error?.message,
        // });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  const generateAccountNumber=async()=>{
    setLoading(true);
    try {
      const response = await changePaymentMethod({
          "transaction_id": details?.id,
          "payment_type": "transfer",
          "email": details?.email ?? user?.data?.email,
          "userId": user?.data?.id //"b406f9e8-fb58-4ae9-bca6-c6b20e9b5549"
      });

      // console.log(response);
      const res = response?.data?.data;
      const status = response?.data?.data? true: false
      await updateData(res);
      // return {data: res, status, message: response?.data?.message};
  } catch (error) {
      const errorMessage = error?.response?.data?.message ?? "Unexpected error! please try again";

      openModal({status: false, message: errorMessage});

  }
  finally{
    setLoading(false);
  }
  }

  return (
    <>
      {transferStep?.state === "success" || transferStep?.state === "error" ? (
        <MandateTransferResult
          setTransferStep={setTransferStep}
          transferStep={transferStep}
          closeModal={handleCloseModal}
          // bills = 
        />
      ) : transferStep?.step === 0 ? (
        <div className="col-lg-10 col-md-6 col-12 mx-auto">
          <h5 className="text-center mb-3 tw-text-[14px] tw-font-[300]">
            Please make a transfer to the account specified below:
          </h5>
          <div className="max-w-l mx-auto tw-border tw-rounded-[8px] tw-border-[#CFCDCC99] p-4">
            <p className="fw-semibold text-center mb-2">
              Transfer information
            </p>
            <div className="tw-flex tw-flex-col tw-gap-y-4">
              {transferCred?.map((item, index) => (
                <div
                  className="tw-grid tw-grid-cols-3 tw-gap-x-10"
                  key={index + "__" + item?.id}
                >
                  <p className="my-auto tw-font-[500] tw-text-[14px]">
                    {item?.label}:
                  </p>
                  <p className={`my-auto ${!item?.copiable && "tw-col-span-2"}`}>
                    {item.label === "Amount"
                      ? "NGN " + formNumberOnly(item?.value)
                      : item?.value}
                  </p>
                  {item?.copiable && (
                    <p
                      className="tw-text-[#2C9DB4E5] tw-text-[14px] cursor-pointer"
                      onClick={() => handleCopy(item?.value, index)}
                    >
                      {copied?.status && copied?.index === index
                        ? "copied "
                        : "copy "}
                      <ContentCopyIcon
                        fontSize={"14px"}
                        sx={{ color: "#2C9DB4E5" }}
                      />
                    </p>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-3 text-center">
              {timeRemaining <= 0 ? (
                <div className="tw-flex tw-flex-col tw-items-center">
                  <p className="tw-text-[300] tw-text-[13px]">
                    {" "}
                    <small style={{ color: "#940B0B", fontSize: "16px" }}>
                      This account details has expired
                    </small>
                  </p>
                 <Button type="text" onClick={generateAccountNumber} className="tw-text-[#033F4B] tw-font-[700] tw-w-fit" loading={loading}>Generate New account</Button>
                </div>
              ) : (
                <div className="tw-flex tw-items-center text-center">
                  <p className="tw-text-[300] tw-text-[13px]">
                    This {details?.bank_name} account expires in
                  </p>
                  <div className="rounded-pill w-auto py-1 tw-px-3 tw-text-[#033F4B] tw-font-[700]">
                    {timeRemaining > 0 ? formatTime(timeRemaining) : "00:00"}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mx-auto d-flex flex-column gap-3 mt-3 ">
            <Button
              shape="round"
              size="large"
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "16px",
                height: "55px",
              }}
              className="tw-w-full"
              loading={isLoading}
              onClick={confirmTransferDone}
            >
              I’ve sent the money
            </Button>
            <Button
              shape="round"
              size="large"
              style={{
                background: "#FFFF",
                border: "2px solid #033F4B",
                color: "#033F4B",
                fontSize: "16px",
                height: "55px",
              }}
              className="tw-w-full"
              onClick={() => setPaymentMethod("anotherMethod")}
            >
              Other payment channel
            </Button>
          </div>
        </div>
      ) : (
        <CheckingMandateTransfer
          transferStep={transferStep}
          setTransferStep={setTransferStep}
        />
      )}
    </>
  );
};

export default PayWithTransfer;
