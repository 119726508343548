import React, { useState, useEffect } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { TextField } from "@mui/material";
import { Button } from "antd";
import { formatNumber } from "../../../utils/formatNumber";
import { useInitiateBill } from "../../../redux/mutation/postMutation";
import BillAuthComp from "../BillAuthComp";
import { useUserData } from "../../../components/hooks/useUserData";

import {usePaymentModal} from "../../../redux/hooks/zustands/usePaymentModal"

export default function ConfirmElectricityInfo({ formik, setNextAction, handleClick, payloadData }) {

  // console.log(payloadData)


  const user = useUserData();

  const [openModal, setOpenModal] = useState(false);

  const { openModal:openPaymentModal} = usePaymentModal();


  const [payNowLoading, setPayNowLoading] = useState(false);
  



  const initiateBillMutation = useInitiateBill();

  const handlePayNowFn = () => {
    console.log(payloadData?.data?.products[0])

    initiateBillMutation.mutate(payloadData?.data?.products[0]);
  };

  const handlePrevAction=()=>{
    setNextAction("")
  }
  

  const handleNextAction = () => {
    if(user?.status){
      setNextAction("checkout");
    }
    else{
      setOpenModal(true);
    }
  };
  

const handleCloseModal = () => {
    setOpenModal(false);
  };


  useEffect(() => {
    if (initiateBillMutation?.isLoading) {
      setPayNowLoading(true);
    } else if (initiateBillMutation?.isSuccess) {
      setPayNowLoading(false);
      const res = initiateBillMutation?.data?.data;



      openPaymentModal({...res?.data, email: payloadData?.data?.products?.[0]?.email})

      // if(res?.data?.link){
      //   window.location.href = res?.data?.link;
      // }

    } else if (initiateBillMutation?.isError) {
      setPayNowLoading(false);
      let msg = initiateBillMutation?.error?.response?.data?.message;
      let msg2 = initiateBillMutation?.error?.response?.statusText;

      handleClick(msg ?? msg2, "error", {
        vertical: "top",
        horizontal: "right",
      });
    }
  }, [initiateBillMutation?.status]);

  return (
    <>
      <div className="col-lg-8 col-12 mx-lg-auto mx-2">
      <div className="d-flex align-items-end justify-content-end">
          <span className="cursor-pointer" onClick={handlePrevAction}>
            Back
          </span>
        </div>
        <AuthHeader title={"Confirm Information"} />
        <div>
         
              <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                    Meter Number
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>
                    {/* {formik?.values?.distributionCompany?.user?.accountNumber} */}
                  {payloadData?.data?.products[0]?.meter}

                  </small>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                    Meter Name
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>
                    {/* {formik?.values?.distributionCompany?.user?.name} */}
                    {payloadData?.data?.products[0]?.account_name}
                  </small>
                </div>
              </div>
              {/* <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                    Ref Code
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>123456789012334</small>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                    Unit
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>120 kWh</small>
                </div>
              </div> */}
              <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                    Amount
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>
                    {/* {formatNumber(formik?.values?.amount)} */}
                    {formatNumber(payloadData?.data?.products[0]?.amount)}
                    </small>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                  Surcharge
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>{formatNumber(100)}</small>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-5 col-5">
                  <p className="text-dark my-auto" style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}>
                  Total Amount
                  </p>
                </div>
                <div className="col-lg-7 col-7">
                  <small className="" style={{color: "#4F4F4F", fontSize: "14px", fontWeight: 400,}}>{formatNumber(parseFloat(payloadData?.data?.products[0]?.amount) + 100)}</small>
                </div>
              </div>
           
          <div className="mobileNumber col-12" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="mobileNumber">Mobile Number *</label>
            </div>
            <TextField
              type="tel"
              name="mobileNumber"
              // required
              error={formik?.touched.mobileNumber && formik?.errors.mobileNumber}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              value={formik?.values.mobileNumber}
              placeholder="08012345678"
              fullWidth
              variant="outlined"
              size="medium"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik?.touched.mobileNumber && formik?.errors.mobileNumber}
            </small>
            <p><small>Token generated will be sent to this number</small></p>
          </div>

          <div className="d-flex flex-column gap-3">
            <Button
              shape="round"
              size="large"
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "18px",
                height: "55px",
              }}
              loading={payNowLoading}
              onClick={handlePayNowFn}
              
            >
              Pay Now
            </Button>
            <Button
              shape="round"
              size="large"
              style={{
                background: "#FA9E17",
                color: "#fff",
                fontSize: "18px",
                height: "55px",
              }}

              onClick={handleNextAction}

            >
              Get it now and pay later
            </Button>
          </div>
        </div>
      </div>


      <BillAuthComp handlePayNowFn={handlePayNowFn} payNowLoading={payNowLoading} openModal={openModal} handleCloseModal={handleCloseModal} billData={payloadData} />
    </>
  );
}
