import React, { useState, useEffect } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { Checkbox } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { formatNumber } from "../../../utils/formatNumber";
import moment from "moment";
import { useUserData } from "../../../components/hooks/useUserData";
import { useCreditMarketPlace } from "../../../redux/mutation/postMutation";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ROUTES } from "../../../routes/route";
import { useQueryClient } from "@tanstack/react-query";
import TransactionPin from "../../../components/Auth/TransactionPin/TransactionPin";
import { useMandateModal } from "../../../redux/hooks/zustands/useMandateModal";

export default function AirtimeCheckout({
  formik,
  handleClick,
  setNextAction,
  payloadData,
  openModal: openResponseModal,
}) {
  const [modal, contextHolder] = Modal.useModal();


  const navigate = useNavigate();

  const { data } = useUserData();

 



const { openModal : openMandateModal } = useMandateModal();
  

  const marketPlaceMutation = useCreditMarketPlace();

  const queryClient = useQueryClient();

  const [isAgreed, setIsAgreed] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const hasSubscription = data?.subscriptions?.length > 0;






  const serviceFee =
    payloadData?.data?.products[0]?.amount *
    (parseFloat(formik?.values?.marketPlace?.service_fee ?? 0) / 100);

  const totalAmount =
    serviceFee + parseFloat(payloadData?.data?.products[0]?.amount);

  const repaymentDate = hasSubscription
    ? moment()
        .add(data?.subscriptions[0]?.plan?.repayment_duration, "days")
        .format("DD MMMM, YYYY")
    : null;

  const confirmHandlePayLater = (pin) => {
    if (isAgreed) {
      //checking of subscription status
      if (
        hasSubscription &&
        data?.creditEstimate?.NumberOfTimes >=
          data?.subscriptions[0]?.plan?.credit_times_limit
      ) {
        setNextAction("maximum_exceeded");
      } else if (data?.subscriptions[0]?.status) {
        marketPlaceMutation.mutate(
          { ...payloadData, pin: String(pin) },
          {
            onSuccess: () => {
              queryClient.invalidateQueries("user_order");
            },
          }
        );
      } else {
        modal.confirm({
          title: "Important Notice!!",
          icon: <ExclamationCircleOutlined />,
          content:
            "You don't have an active subscription plan, you have a pending subscription payment, do you want to proceed to subscription?",
          okText: "Continue",
          cancelText: "Cancel",
          onOk: () => {
            navigate(ROUTES.subscription);
          },
        });
      }
    } else {
      handleClick(
        "You must agree to our terms and condition before proceeding!",
        "error",
        { vertical: "top", horizontal: "center" }
      );
    }
  };

  useEffect(() => {
    if (marketPlaceMutation.isLoading) {
      setIsLoading(true);
    } else if (marketPlaceMutation.isSuccess) {
      setIsLoading(false);
      // console.log(marketPlaceMutation?.data?.data);
      let response = marketPlaceMutation?.data?.data;

      if(response?.data?.mandate){

        // openMandateModal(response?.data?.initiate_mandate_data)

        modal.success({
          title: "Congratulation!!!",
          content: response?.message,
          onOk: () => {
            if(response?.data?.mandate){
              console.log(response?.data)
              openMandateModal(response?.data?.mandate)
            }
            // else{
            //   navigate(ROUTES.shop);
            // }
          },
        });

      }
      else if (response?.data?.order) {
        openResponseModal(response?.data);
      }
      //  else {
      //   modal.success({
      //     title: "Congratulation!!!",
      //     content: response?.message,
      //     onOk: () => {
      //       if (response?.data?.mandate) {
      //         console.log(response?.data);

      //         openMandateModal(response?.data?.mandate);
      //       } else {
      //         navigate(ROUTES.shop);
      //       }
      //     },
      //   });
      // }
    } else if (marketPlaceMutation.isError) {
      setIsLoading(false);
      let msg = marketPlaceMutation?.error?.response?.data?.message;
      let msg2 = marketPlaceMutation?.error?.response?.statusText;
      let msg3 = marketPlaceMutation?.error?.message;
      console.log(marketPlaceMutation?.error);
      handleClick(msg ?? msg2 ?? msg3, "error");
      // handleCancelModal()
    }
  }, [marketPlaceMutation?.status]);

  //modal
  const handleOpenModal = () => {
    // console.log(payloadData)
    if (isAgreed) {
      setOpenModal(true);
    } else {
      handleClick(
        "You must agree to our terms and condition before proceeding!",
        "error",
        { vertical: "top", horizontal: "center" }
      );
    }
  };

  const handlePrevAction = () => {
    setNextAction("confirm_information");
  };

  const handleCancelModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="col-lg-11 col-12 mx-auto">
        <div className="d-flex align-items-end justify-content-end">
          <span className="cursor-pointer" onClick={handlePrevAction}>
            Back
          </span>
        </div>
        <div className="col-lg-10 mx-auto">
          <AuthHeader title={"Pay Later With Vouch"} />
          <p className="text-center mt-2 mb-3">You are requesting to defer payment</p>
          <div className={`row mx-2 mb-2`}>
            <div className="col-lg-5 col-md-7 col-7">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Phone Number
              </p>
            </div>
            <div className="col-lg-7 col-md-5 col-5">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {payloadData?.data?.products[0]?.phone}
              </small>
            </div>
          </div>
          <div className="row mx-2 mb-2">
            <div className="col-lg-5 col-md-7 col-7">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Service Type
              </p>
            </div>
            <div className="col-lg-7 col-md-5 col-5">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {payloadData?.data?.products[0]?.disco?.toUpperCase()}
              </small>
            </div>
          </div>
          <div className="row mx-2 mb-2">
            <div className="col-lg-5 col-md-7 col-7">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Sub-total
              </p>
            </div>
            <div className="col-lg-7 col-md-5 col-5">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formatNumber(payloadData?.data?.products[0]?.amount)}
              </small>
            </div>
          </div>
          <div className="row mx-2 mb-2">
            <div className="col-lg-5 col-md-7 col-7">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Service fee ({formik?.values?.marketPlace?.service_fee ?? 0}% of
                sub-total)
              </p>
            </div>
            <div className="col-lg-7 col-md-5 col-5">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formatNumber(serviceFee)}
              </small>
            </div>
          </div>
          <div className="row mx-2 mb-2">
            <div className="col-lg-5 col-md-7 col-7">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Total Amount
              </p>
            </div>
            <div className="col-lg-7 col-md-5 col-5">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formatNumber(totalAmount)}
              </small>
            </div>
          </div>
        </div>
        <hr />
        <div className="repayment_date px-3 py-3">
          <label htmlFor="repayment_date" style={{ fontWeight: "500" }}>
            Repayment date
          </label>
          <p className="">{repaymentDate ?? "xxxxx"}</p>
        </div>
        <hr />
        <div className="repayment_date px-3 py-3">
          <label htmlFor="repayment_date" style={{ fontWeight: "500" }}>
            Terms and Condition
          </label>
          <p className="">
          <small> There will be 0.3% of the total amount charged in this transaction
          per day for every day of default.</small>
          </p>
        </div>
        <div className="text-center d-flex align-items-center mt-2">
          <Checkbox onChange={(e) => setIsAgreed(e.target.checked)} />
          <label
            htmlFor="terms_condition"
            className="mx-2 tw-font-semibold"
            style={{ fontSize: "16px" }}
          >
           <small> I hereby agree to the{" "}
            <Link to="" className="text-decoration-none">
              Terms and conditions
            </Link>{" "}
            in this checkout</small>
          </label>
        </div>
        <div className="col-lg-4 mx-2 mx-lg-auto mt-3">
          <Button
            shape="round"
            size="large"
            style={{
              background: !isAgreed? "#f0f0f0" : "#FA9E17",
              color: !isAgreed ? "#033F4B" : "#fff",
              fontSize: "18px",
              width: "100%",
              height: "55px",
            }}
            disabled={!isAgreed}
            loading={isLoading}
            onClick={handleOpenModal}
          >
            Confirm
          </Button>
        </div>
      </div>

      <TransactionPin
        finalFn={confirmHandlePayLater}
        openModal={openModal}
        closeModal={handleCancelModal}
      />

      {contextHolder}
    </>
  );
}
